import React from 'react';
import './Footer.css'


const Footer = () => {
  return (
    <div>
      <footer className="footer">
        <div className="footer-shape">
          <svg className="shape-fill" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
            <path fill="#fff" fillOpacity="1"
              d="M0,192L48,165.3C96,139,192,85,288,85.3C384,85,480,139,576,144C672,149,768,107,864,96C960,85,1056,107,1152,112C1248,117,1344,107,1392,101.3L1440,96L1440,0L1392,0C1344,0,1248,0,1152,0C1056,0,960,0,864,0C768,0,672,0,576,0C480,0,384,0,288,0C192,0,96,0,48,0L0,0Z">
            </path>
          </svg>
        </div>
        <div className="main-footer">
          <div className="container">
            <div className="row">
              <div className="footer-box col-xl-3 col-lg-3 col-md-6 col-sm-12">
                <div className="footer-logo">
                  <img className="img-fluid" src="../images/logo-black.png" alt="logo" />
                </div>
                <div className="footer-box about-box">
                  <div className="box-content">
                    <div className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry.</div>
                    <ul className="social-icon-two">
                      <li><a href="!"><i className="fab fa-twitter"></i></a></li>
                      <li><a href="!"><i className="fa-brands fa-facebook-f"></i></a></li>
                      <li><a href="!"><i className="fab fa-pinterest"></i></a></li>
                      <li><a href="!"><i className="fab fa-instagram"></i></a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-box col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <div className="box links-box">
                  <div className="box-content">
                    <ul className="user-links">
                      <li><a href="Home">Home</a></li>
                      <li><a href="SignIn">Sign In</a></li>
                      <li><a href="SignUp">Sign Up</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-box col-xl-2 col-lg-2 col-md-6 col-sm-12">
                <div className="box links-box">
                  <div className="box-content">
                    <ul className="user-links">
                      <li><a href="Qrcode">Qrcode</a></li>
                      <li><a href="About">About Us</a></li>
                      <li><a href="Contact">Contact Us</a></li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="footer-box col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div className="box newsletter-box">
                  <h6 className="box-title">Newsletter</h6>
                  <div className="box-content">
                    <div className="text">Lorem Ipsum is simply dummy text of the printing and typesetting industry</div>
                    <div className="subscribe-form">
                      <form method="post" action="#">
                        <div className="form-group">
                          <input type="email" name="email" className="email" placeholder="Email Address" required="" />
                          <button type="button" className="theme-btn"><i className="fa fa-paper-plane"></i></button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-bottom">
          <div className="container">
            <div className="row align-items-center text-center">
              <div className="col-lg-12 col-md-12">
                <p className="copyright-text">© 2024 by Reyone.</p>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
}

export default Footer;
