// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: "Outfit", sans-serif;
    margin-top: 0%;
}
body{
    background-color: #F5F5F5;
}

a{
    text-decoration: none;
    color: inherit;
    line-height: 1;
    cursor: pointer;
}

.containe{


    padding-left: 2%;
    padding-right: 0%;
}
`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":";AAEA;IACI,SAAS;IACT,UAAU;IACV,sBAAsB;IACtB,iCAAiC;IACjC,cAAc;AAClB;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;IACrB,cAAc;IACd,cAAc;IACd,eAAe;AACnB;;AAEA;;;IAGI,gBAAgB;IAChB,iBAAiB;AACrB","sourcesContent":["\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');\n*{\n    margin: 0;\n    padding: 0;\n    box-sizing: border-box;\n    font-family: \"Outfit\", sans-serif;\n    margin-top: 0%;\n}\nbody{\n    background-color: #F5F5F5;\n}\n\na{\n    text-decoration: none;\n    color: inherit;\n    line-height: 1;\n    cursor: pointer;\n}\n\n.containe{\n\n\n    padding-left: 2%;\n    padding-right: 0%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
