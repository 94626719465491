import React from 'react';
import './About.css'; // Importing CSS for styling

const AboutUs = () => {
  return (
    <div className='cadd6'>
    <div className="about-container">
      {/* Header Section */}
      <section className="about-hero">
        <h1>Welcome to QuickCatalog!</h1>
        <p>Efficient, Powerful, and User-Friendly Catalog Management Solutions</p>
      </section>

      {/* Mission Section */}
      <section className="about-mission">
        <h2>Our Mission</h2>
        <p>
          Our mission is to empower businesses of all sizes to effortlessly create, manage, and distribute their product catalogs.
        </p>
        <p>
          We simplify catalog management so you can focus on what matters most—growing your business.
        </p>
      </section>

      {/* Offerings Section */}
      <section className="about-offerings">
        <h2>What We Offer</h2>
        <div className="offerings-grid">
          <div className="offering-item">
            <i className="fas fa-laptop"></i>
            <h3>Intuitive Software</h3>
            <p>Easily upload products, customize layouts, and generate stunning catalogs with just a few clicks.</p>
          </div>
          <div className="offering-item">
            <i className="fas fa-paint-brush"></i>
            <h3>Customization</h3>
            <p>Tailor your catalogs with a wide range of templates and design options to suit your brand identity.</p>
          </div>
          <div className="offering-item">
            <i className="fas fa-robot"></i>
            <h3>Automation</h3>
            <p>Automate updates and ensure your catalog is always current and accurate.</p>
          </div>
          <div className="offering-item">
            <i className="fas fa-headset"></i>
            <h3>Support & Resources</h3>
            <p>Get help from our dedicated team and access tutorials to make the most of QuickCatalog.</p>
          </div>
        </div>
      </section>

      {/* Vision Section */}
      <section className="about-vision">
        <h2>Our Vision</h2>
        <p>
          We envision a world where businesses can focus on growth and creativity, without being bogged down by cumbersome catalog management.
        </p>
      </section>

      {/* Call-to-Action Section */}
      <section className="about-cta">
        <h2>Join Us on Our Journey</h2>
        <p>Discover how QuickCatalog can transform the way you manage your product catalogs.</p>
        <button className="cta-button">Explore QuickCatalog</button>
      </section>
    </div>
    </div>
  );
};

export default AboutUs;

